import * as React from 'react';
// import '../sass/main.scss';

import FeatherIcon from 'feather-icons-react';
import LayoutDefault from '../layouts/default';
import TextHeader from '../components/textHeader';

// markup
const BlogPostOnePage = () => {
  return (
    <>
      <LayoutDefault>
        <section className="bg-light py-10">
          <div className="container px-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-10 col-xl-8">
                <div className="single-post">
                  <h1>
                    Boots on the Ground, Inclusive Thought Provoking Ideas
                  </h1>
                  <p className="lead">
                    Empower communities and energize engaging ideas; scale and
                    impact do-gooders while disruptring industries. Venture
                    philanthropy benefits corporations and people by moving the
                    needle.
                  </p>
                  <div className="d-flex align-items-center justify-content-between mb-5">
                    <div className="single-post-meta me-4">
                      <img
                        className="single-post-meta-img"
                        src="assets/img/illustrations/profiles/profile-1.png"
                      />
                      <div className="single-post-meta-details">
                        <div className="single-post-meta-details-name">
                          Valerie Luna
                        </div>
                        <div className="single-post-meta-details-date">
                          Feb 5 · 6 min read
                        </div>
                      </div>
                    </div>
                    <div className="single-post-meta-links">
                      <a href="#!">
                        <i className="fab fa-twitter fa-fw" />
                      </a>
                      <a href="#!">
                        <i className="fab fa-facebook-f fa-fw" />
                      </a>
                      <a href="#!">
                        <i className="fas fa-bookmark fa-fw" />
                      </a>
                    </div>
                  </div>
                  <img
                    className="img-fluid mb-2 rounded"
                    src="https://source.unsplash.com/vZJdYl5JVXY/990x540"
                  />
                  <div className="small text-gray-500 text-center">
                    Photo Credit: Unsplash
                  </div>
                  <div className="single-post-text my-5">
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Odit similique distinctio quidem blanditiis architecto
                      ullam a itaque quisquam nihil! Unde ducimus deleniti
                      exercitationem minima, molestiae ab saepe libero.
                      Doloribus, a?
                    </p>
                    <p>
                      Magnam amet labore exercitationem maxime consectetur
                      molestias quas quia dicta, praesentium minus illum quis
                      fuga, fugiat velit voluptate sed nostrum ipsam atque.
                    </p>
                    <p>
                      Quaerat voluptas natus velit deleniti reprehenderit vero
                      ad eos ab reiciendis. Libero dignissimos temporibus ipsam
                      sint dolores voluptate consequatur debitis tempora
                      doloremque.
                    </p>
                    <h4>Creating Something New</h4>
                    <p>
                      Laborum placeat quas accusantium vitae perferendis dolores
                      possimus tempora, qui consectetur hic ullam autem. Enim,
                      rerum obcaecati numquam quaerat necessitatibus voluptatem?
                      Repellat!
                    </p>
                    <p>
                      Quasi, quos quaerat? Sint at odit possimus ullam saepe
                      suscipit officiis nobis eaque, laudantium ut earum tempore
                      repellendus mollitia odio nam! Unde?
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nisi explicabo unde perferendis reprehenderit ullam nobis?
                      Laborum amet voluptatem sunt natus? Tempore commodi
                      corporis accusamus laudantium assumenda blanditiis aut
                      nobis culpa.
                    </p>
                    <h4>It's time to build your new project</h4>
                    <p>
                      Facilis enim voluptatibus qui voluptatum nemo non, facere,
                      fugiat deserunt dicta ab sunt in sequi, assumenda nobis
                      ipsam quidem corporis. Nemo, aliquam.
                    </p>
                    <p>
                      Illum numquam sapiente debitis similique, a accusantium
                      quisquam recusandae! Nihil quia nulla blanditiis. Nobis
                      numquam iure facilis consequuntur beatae eos adipisci
                      doloremque!
                    </p>
                    <p>
                      Voluptate reiciendis nisi tempora laboriosam commodi sequi
                      sapiente natus aut ab, cum aspernatur illo. Nobis
                      laboriosam excepturi iste earum. Error, ab eius?
                    </p>
                    <p>
                      Quam, nesciunt iusto, praesentium amet necessitatibus quod
                      porro libero voluptates soluta nostrum quisquam delectus
                      repellendus totam accusamus sint magni dolore atque qui.
                    </p>
                    <img
                      className="img-fluid mb-2 rounded"
                      src="https://source.unsplash.com/sv8oOQaUb-o/990x540"
                    />
                    <div className="small text-gray-500 text-center">
                      Photo Credit: Unsplash
                    </div>
                    <h4>It's time to build your new project</h4>
                    <p>
                      Laborum placeat quas accusantium vitae perferendis dolores
                      possimus tempora, qui consectetur hic ullam autem. Enim,
                      rerum obcaecati numquam quaerat necessitatibus voluptatem?
                      Repellat!
                    </p>
                    <p>
                      Quasi, quos quaerat? Sint at odit possimus ullam saepe
                      suscipit officiis nobis eaque, laudantium ut earum tempore
                      repellendus mollitia odio nam! Unde?
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nisi explicabo unde perferendis reprehenderit ullam nobis?
                      Laborum amet voluptatem sunt natus? Tempore commodi
                      corporis accusamus laudantium assumenda blanditiis aut
                      nobis culpa.
                    </p>
                    <hr className="my-5" />
                    <div className="text-center">
                      <a
                        className="btn btn-transparent-dark"
                        href="page-blog-overview.html"
                      >
                        Back to Blog Overview
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-border-rounded text-dark">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>
      </LayoutDefault>
    </>
  );
};

export default BlogPostOnePage;
